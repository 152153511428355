import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
import { graphql } from 'gatsby';
export const data = graphql`
  query {
    file(relativePath: { eq: "AmazonSmile.jpg" }) {
      childImageSharp {
        fixed(width: 364, quality: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Img = makeShortcode("Img");
const layoutProps = {
  data,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Every gift counts! Watchung Cooperative counts on the generous financial gifts and volunteer support of its community throughout the year. These allow us to enhance educational and experiences for our students.`}</p>
    <p>{`From morning play to special programs and family presentations, we make the most of every resource to provide a meaningful environment for children in our Creative Play and Discovery Pre-K classes.`}</p>
    <p>{`We are deeply grateful for the loyalty and generosity of parents, alumni parents, alumni, grandparents and friends who have provided support over the years.Thank you for supporting Watchung Cooperative.`}</p>
    <h2>{`Annual Spring Auction: March 5, 2021`}</h2>
    <p>{`Our most important fundraising event or the year is our Annual Spring Auction. This event brings together our entire adult community at Watchung. Parents, faculty and staff, alumni and friends mingle, eat wonderful food, and enjoy a silent and live auction filled with wonderful items procured by our Auction Committee and each family in the school. This year's auction may be virtual.  `}</p>
    <p>{`Please check back for updates as we continue to plan the Auction!`}</p>
    <h2>{`Direct Donations`}</h2>
    <p>{`The Co-op accepts donations by `}<a parentName="p" {...{
        "href": "https://www.paypal.com"
      }}>{`credit card`}</a>{` or by check mailed to the Co-op Treasurer at the following address:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Watchung Cooperative Nursery School`}<br parentName="p"></br>{`
`}{`Attn: Treasurer`}<br parentName="p"></br>{`
`}{`24 N Fullerton Ave`}<br parentName="p"></br>{`
`}{`Montclair, NJ 07042  `}</p>
    </blockquote>
    <p>{`You may also call our Director, Christi Porter Johnson, at `}<a parentName="p" {...{
        "href": "tel:9737834535"
      }}>{`(973) 783-4535`}</a>{` with any questions or to make a donation by phone.`}</p>
    <h2>{`Corporate Matching`}</h2>
    <p>{`More than 1,000 companies match donations made by their employees and retirees to independent schools. The value of your gift can be doubled (or even tripled) through corporate matching gifts!`}</p>
    <h2>{`Amazon Smile`}</h2>
    <Img fixed={props.data.file.childImageSharp.fixed} alt="The Amazon Smile logo. It says 'You shop. Amazon gives." style={{
      margin: ' 1.75rem auto 1.75rem',
      display: 'block'
    }} mdxType="Img" />
    <p>{`AmazonSmile is a simple way for you to support your favorite charitable organization every time you shop, at no cost to you. AmazonSmile is available at `}<a parentName="p" {...{
        "href": "https://smile.amazon.com/"
      }}>{`smile.amazon.com`}</a>{` on your web browser and can be activated in the Amazon Shopping app for iOS and Android phones. When you shop at AmazonSmile, Amazon will donate 0.5% of your eligible purchases to the charitable organization of your choice. You can choose to support Watchung Cooperative Nursery School.  `}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      